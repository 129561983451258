<template>
  <el-card class="box-card dense quotes-list">
    <div slot="header" class="clearfix flex-space align-center">
      <el-form :model="queryParams" inline class="dense full-width" size="mini">
        <el-form-item label="">
          <el-select
            v-model="queryParams.hub_eq"
            filterable
            clearable
            size="mini"
            style="width: 200px"
            placeholder="Choose Hub"
          >
            <el-option
              v-for="item in $store.hubs"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="queryParams.zip_code_eq"
            clearable
            size="mini"
            placeholder="Zip Code"
            style="width: 100px"
          />
        </el-form-item>
        <el-form-item label="">
          <el-select
            v-model="queryParams.move_type_eq"
            clearable
            size="mini"
            style="width: 120px"
            placeholder="Move Type"
          >
            <el-option
              v-for="item in move_types"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="loadQuote">Search</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-space-v">
      <el-table
        size="small"
        :data="items"
        style="width: 100%"
        empty-text="No Data"
        height="100%"
      >
        <el-table-column prop="hub" label="Hub" />
        <el-table-column prop="move_type" label="Move Type" />
        <el-table-column prop="zip_code" label="Zip Code" />
        <el-table-column label="Base + Fuel" align="right">
          <template slot-scope="scope">
            {{ baseRate(scope.row) | toCurrency }}
            <div>
              {{ (scope.row.data.expected || {}).base_rate | toCurrency }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Drop Rate" align="right">
          <template slot-scope="scope">
            <div>{{ dropRate(scope.row) | toCurrency }}</div>
            <div>
              {{ (scope.row.data.expected || {}).drop_rate | toCurrency }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Refer. No." prop="ref_no"> </el-table-column>
        <el-table-column prop="created_at" label="Created At" />
        <el-table-column prop="expired_date" label="Expires On">
          <template slot-scope="scope">
            {{ scope.row.expired_date | usFormat }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="currentPage"
        :page-sizes="[25, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageSize"
        @size-change="sizeChange"
        @current-change="pageChange"
        :disabled="total == 0"
      />
    </div>
  </el-card>
</template>
<script>
export default {
  name: "spotQuoteList",
  props: {
    move_types: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      total: 0,
      pageSize: 25,
      currentPage: 1,
      items: [],
      queryParams: {},
      tableData: [],
    };
  },
  mounted() {
    this.loadQuote();
  },
  computed: {
    baseRate() {
      return function (item) {
        const items = item.data.items || [];
        return (items.find((o) => /Base.*Fuel/.test(o.name)) || {})[
          "unit_price"
        ];
      };
    },
    dropRate() {
      return function (item) {
        const items = item.data.items || [];
        return (
          (items.find((o) => /Trucking - Drop/.test(o.name)) || {})[
            "unit_price"
          ] || "- -"
        );
      };
    },
  },
  methods: {
    loadQuote() {
      let params = {
        page: this.currentPage,
        page_size: this.pageSize,
      };
      this.$http
        .get(
          "/api/v1/spot_quotes",
          this.$http.mergeQueryParams(params, this.queryParams)
        )
        .then((response) => {
          this.items = response.data.spot_quotes;
          this.total = response.data.total;
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    sizeChange(val) {
      this.pageSize = val;
      this.loadQuote();
    },
    pageChange(val) {
      this.currentPage = val;
      this.loadQuote();
    },
  },
};
</script>