<template>
  <div class="quotes">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Quote Engine">
        <el-card class="box-card dense">
          <div slot="header" class="clearfix flex-space align-center">
            <el-form inline class="dense full-width" size="mini">
              <div class="flex-space">
                <div>
                  <el-form-item label="">
                    <el-select
                      v-model="queryParams.hub"
                      filterable
                      clearable
                      size="mini"
                      style="width: 200px"
                      placeholder="Choose Hub"
                      @change="loadQuotes"
                    >
                      <el-option
                        v-for="item in $store.hubs"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input
                      v-model="queryParams.zip_code"
                      size="mini"
                      style="width: 100px"
                      placeholder="Zip Code"
                      clearable
                      @change="loadQuotes"
                    />
                  </el-form-item>
                  <el-form-item label="">
                    <el-select
                      v-model="queryParams.move_type"
                      filterable
                      size="mini"
                      style="width: 80px"
                      @change="loadQuotes"
                    >
                      <el-option
                        v-for="item in options.move_types"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </el-form-item>
                </div>
                <div
                  style="position: absolute; right: 0px"
                  v-if="!isEmpty(quotes)"
                >
                  <el-form-item label="Your Expected Rates">
                    <el-input
                      placeholder="Base Rate"
                      v-model="baseRate"
                      style="width: 100px"
                    />
                  </el-form-item>
                  <el-form-item label="" v-if="dropMove">
                    <el-input
                      placeholder="Drop Rate"
                      style="width: 100px"
                      v-model="dropRate"
                    />
                  </el-form-item>
                  <el-form-item label="">
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      :disabled="_.isEmpty(quotes)"
                      @click="sendQuote"
                      >Send Inquiry</el-button
                    >
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <el-table
            :data="quotes"
            size="small"
            style="width: 100%"
            empty-text="No Data"
          >
            <el-table-column label="Category" prop="name" width="300">
              <template slot-scope="scope">
                <b>{{ scope.row.name }}</b>
              </template>
            </el-table-column>
            <el-table-column
              label="Price"
              width="150"
              header-align="right"
              align="right"
              prop="unit_price"
            >
            </el-table-column>
            <el-table-column label="Description" prop="description">
            </el-table-column>
          </el-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="History" lazy>
        <SpotQuoteList :move_types="options.move_types" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SpotQuoteList from "@/views/customer/SpotQuoteList.vue";
export default {
  name: "Quotes",
  components: {
    SpotQuoteList,
  },
  data() {
    return {
      activeTab: "",
      options: {
        move_types: ["Live", "Drop"],
      },
      quotes: [],
      queryParams: {
        move_type: "Live",
        mode: "visitor",
      },
      groups: [],
      onlyQuote: false,
      baseRate: null,
      dropRate: null,
    };
  },
  async mounted() {
    this.$actions.setHubs(await this.loadHubs());
  },
  computed: {
    quotable() {
      return !this._.isEmpty(this.queryParams.hub);
    },
    dropMove() {
      return this.queryParams.move_type == "Drop";
    },
  },
  methods: {
    loadQuotes() {
      if (this.quotable) {
        this.$http
          .get("/api/v1/quote_engines", this.queryParams)
          .then((response) => {
            this.quotes = response.data;
          });
      }
    },
    sendQuote() {
      let spot_quote = Object.assign(
        {
          items: [],
          expected: {
            base_rate: this.baseRate,
            drop_rate: this.dropRate,
          },
        },
        this.queryParams
      );
      this.quotes.forEach((o) =>
        spot_quote.items.push(
          this._.pick(o, ["name", "unit_price", "description"])
        )
      );
      return this.$http
        .post("/api/v1/spot_quotes", {
          spot_quote: spot_quote,
        })
        .then((response) => {
          if (response.status == 201) {
            this.$message.success("Quote was sent successfully!");
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.cname {
  width: 180px;
}
.quotes::v-deep {
  height: calc(100vh - 72px);
  .el-tabs__content {
    height: calc(100vh - 125px);
  }
  .el-card__body {
    height: calc(100vh - 175px);
  }
}
</style>
